
.lightgreydivider {
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
}

.greydivider {
  border-bottom: 1px solid #b6b6b6;
  margin: 10px;
  width: 95%;
}

.vertical-divider {
  width: 1px;
  height: 30px;
  background-color: #e9effa;
}