@import '../modules/JobSeek/components/Job/Job.scss';

.ph-item {
  border: none;
  background: none;

  // @media screen and (max-width: 425px) {
  //   padding: 30px 0px 15px;
  // }
}

.shell-card {
  width: 100%;
  min-height: 135px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  margin-bottom: 10px !important;
  position: relative;
}

#shell-btn {
  width: 150px;
  margin-left: 36px;
  height: 31px;
  border-radius: 15.5px;
}

.shell-color {
  color: #dce3ed;
}

.shell-desc {
  background-color: white !important;
}

#shell-logo {
  height: 50px;
}

#shell-job-title {
  margin-left: 35px;
  height: 15px;
  margin-top: 10px;
  border-radius: 8px;
}

#shell-info {
  margin-left: 35px;
  background-color: white;
}

.ph-row .big, .ph-row.big div {
  height: 36px;
  margin-bottom: 15px;
}

.ph-row div {
  background-color: #dce3ed;
}
  
.sb {
  width: 23%;
  border-radius: 8px;

  @media only screen and (max-width: 1024px) and (min-width: 425px) {
    width: 22%;
  }

  @media screen and (max-width: 375px) {
    width: 20%;
  }
}

.seb {
  width: 11px;
}

.container-fluid {
  margin-left: 30px;
  margin-top: 40px;
}

@media (max-width:475px){
  .container-fluid {
    margin-left: 9px;
    margin-top: 4px;
  }
}

@media (max-width:767px){
  .hidden {
    display: none;
  }
  .ph-item {
    border: none;
  }
}

.divider {
  border-right: 3px solid #ffffff;
  height: 100% !important;
}

#shell-showcase {
  @media screen and (min-width: 1280px) {
    height: 200px;
  }
  @media screen and (max-width: 1279px) {
    height: 170px;
  }
  padding: 0;
}

#shell-showcase-item {
  padding: 0;
  height: 100%;
}

#shell-ph-row {
  height: 100%;
  margin: 0px;

  @media screen and (max-width: 425px) {
    .hide-xs {
      display: none
    }

    .wd-full {
      flex: 100%;
      display: inline-block;
    }
  }

}

.profile-loader-wrapper {
  width: 100%;
  max-width: 290px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding: 20px;
  margin: 0px;
}

.profile-row-1 {
  width: 100%;
  height: 76px;
  display: unset;
  padding: 0px;
}

.profile-img-loader {
  width: 48px;
  height: 48px;
  vertical-align: top;
  display: inline-block;
  border: solid 1px #e8effa;
  border-radius: 50%;
  background-color: #e3e9f0;
  box-shadow: 0 0 0 4px #ffffff inset;
  margin-right: 10px;
}

.profile-content-loader {
  display: inline-block;
  width: 0px;
}

.profile-detail-1 {
  max-width: 95px;
  width: 7.3vw;
  margin-bottom: 6px;
  height: 13px;
  border-radius: 9px;
  background-color: #dce3ed;
  display: inline-block;
}

.profile-detail-2 {
  max-width: 145px;
  width: 11.09vw;
  height: 8px;
  margin-bottom: 3px;
  display: inline-block;
  border-radius: 9px;
  background-color: #e3e9f0;
}

.profile-detail-3 {
  max-width: 125px;
  width: 9.55vw;
  display: inline-block;
  height: 5px;
  border-radius: 3px;
  background-color: #dce3ed;
}

.profile-detail-4 {
  max-width: 35px;
  width: 2.68vw;
  height: 8px;
  border-radius: 9px;
  background-color: #e3e9f0;
  margin-top: 12px;
  margin-left: 14px;
  float: right;
}

.profile-row-2 {
  width: 100%;
  height: 74px;
  border-radius: 8px;
  background-color: #f2f5fa;
  display: inline-block;
  margin-top: -20px;
  padding: 5px 0px 5px 5px;
  span {
    width: 78px;
    height: 64px;
    display: inline-block;
    border-radius: 8px;
    .circle-loader {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin-top: 13px;
      margin-left: 29px;
      margin-bottom: 11px;
      background-color: #dce3ed;
    }
    .line-loader {
      width: 59px;
      height: 8px;
      border-radius: 9px;
      margin-left: 10px;
      background-color: #e3e9f0;
    }
    @media (max-width:1040px){   
      width: 90px;
    }
  }
  a {
    width: 1px;
    height: 60px;
    border-radius: 8px;
    display: inline-block;
    background-color: #e1e8f2;
  } 
  .bg-white {
    background-color: #ffffff;
  }
  .dis-none {
    @media (max-width:1040px){   
      display: none;
    }
  }
}

.profile-row-3 {
  width: 100%;
  height: 40px;
  margin-bottom: -20px;
  display: inline-block;
  border-radius: 3px;
  border: solid 1px #fbf0dd;
  background-color: #fffcf7;
  background: repeating-linear-gradient(-60deg, #fbf0dd, #fffcf7 2px, #fffcf7 8px, #fbf0dd 0);
  .detail-row-1 {
    width: 125px;
    height: 13px;
    border-radius: 9px;
    background-color: #dce3ed;
    margin-top: 14px;
    margin-left: 12px;
    display: inline-block;
    margin-bottom: 13px;
  }
  .detail-row-2 {
    width: 45px;
    height: 8px;
    margin: 16px 13px;
    border-radius: 9px;
    float: right;
    background-color: #dce3ed;
  }
}

.ph-item:before {
  animation: phAnimation 2s linear infinite;
}
