.skeleton {
    background-color: #e2e5eb;
    margin: 0 5px;
    border-radius: 6px;
}

.skeleton.text {
    width: 100%;
    height: 19px;
    margin-bottom: 15px;
}

.skeleton.title {
    width: 50%;
    height: 19px;
    margin-bottom: 15px;
}

.skeleton.avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height: 100px;
}

.skeleton-wrapper {
    margin: 20px auto;
    padding: 10px 15px;
    border-radius: 6px;
    .profile-section {
        display: grid;
        grid-template-columns: 1fr 4fr;
        justify-content: center;
        align-items: center;
        .profile-img-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .skeleton-ads {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .skeleton-filter {
        display: flex;
        align-items: center;
    }
    .skeleton-showcase {
        display: flex;
        align-items: center;
    }
    .skeleton-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}
.skeleton-wrapper.profile-skeleton-container {
    border: solid 1px #ececec;
    cursor: pointer;
    &:hover {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09);
    }
}
.jobfeed-shimmer-container {
    padding: 0 20px;
    .job-card {
        border: solid 1px #ececec;
        .job-description {
            margin: 0;
            #shell-logo {
                margin: 0;
            }
        }
    }
    .jobfeed-shimmer-left-container {
       display: inline-block;
       width: 70%;
       vertical-align: top;
       @media screen and (max-width: 1024px) {
        width: 100%;
       }
       .jobfeed-skeleton-container {
           .jobfeed-skeleton-body {
            margin-top: -50px;
           }
       }
    }
    .jobfeed-shimmer-right-container {
        display: inline-block;
        width: 30%;
        vertical-align: top;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}
.main-skeleton-container {
    position: relative;
    overflow: hidden;
    background-color: #fff;
}

.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.05);
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.2);
    transform: skewX(-20deg);
}

@keyframes loading {
    0% { transform: translateX(-150%) }
    50% { transform: translateX(-60%) }
    100% { transform: translateX(150%) }
}