/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */

 $dotWidth: 10px;
 $dotHeight: 10px;
 $dotRadius: 50%;
 
 $dotColor: #b4b9d8;
 $dotBgColor: $dotColor;
 $dotBeforeColor: $dotColor;
 $dotAfterColor: $dotColor;
 
 $dotSpacing: $dotWidth + $dotWidth/2;

 @mixin dot($width: $dotWidth, $height: $dotHeight, $radius: $dotRadius,$bgColor: $dotBgColor, $color: $dotColor ) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bgColor;
  color: $color;
}

$leftPos: -9000px;
$x1: - $leftPos - $dotSpacing;
$x2: - $leftPos;
$x3: - $leftPos + $dotSpacing;



.dot-pulse {
  position: relative;
  left: -9000px;
  margin-left: 500px;
  background-color: white;

  @include dot;

  box-shadow: $x2 0 0 -5px $dotColor;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;

    @include dot;
  }

  &::before {
    box-shadow: $x1 0 0 -5px $dotBeforeColor;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: $x3 0 0 -5px $dotAfterColor;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: $x1 0 0 -5px $dotBeforeColor;
  }

  30% {
    box-shadow: $x1 0 0 2px $dotBeforeColor;
  }

  60%,
  100% {
    box-shadow: $x1 0 0 -5px $dotBeforeColor;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: $x2 0 0 -5px $dotColor;
  }

  30% {
    box-shadow: $x2 0 0 2px $dotColor;
  }

  60%,
  100% {
    box-shadow: $x2 0 0 -5px $dotColor;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: $x3 0 0 -5px $dotAfterColor;
  }

  30% {
    box-shadow: $x3 0 0 2px $dotAfterColor;
  }

  60%,
  100% {
    box-shadow: $x3 0 0 -5px $dotAfterColor;
  }
}