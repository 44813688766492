#fallback-container {
  height: 100vh;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffaf0;
  position: relative;
  .tech-logo {
    height: 40px;
    position: absolute;
    left: 40px;
    top: 40px;
    cursor: pointer;
  }
  .illustration {
    max-width: 482px;
    min-height: 450px;
    width: 100%;
    height: 100%;
    margin: 0 18.2px 13px 17.1px;
    padding: 1.8px 3.5px 38.9px 1.3px;
    object-fit: contain;
  }
  .text-container {
    font-family: Nunito Sans;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    text-align: center;
    > div:first-child {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 800;
        color: #e9630c;
    }
    > div:nth-child(n+2) {
      font-size: 20px;
    }
    > div:nth-child(2n+2) {
      margin: 10px 0;
      font-weight: 600;
    }
  }
  .button-wrapper {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media (max-width: 425px) {
      flex-direction: column;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}