.hiristTechWrapper {
	top: 0;
	@media only screen and (max-width: 1023px) {
        bottom: 0;
		top: initial;
    }
	position: fixed;
	height: 80px;
	@media only screen and (min-width: 1024px) {
		height: 60px;
	}
	background-color: #000;
	z-index: 10;
	width: 100vw;
	left: 50%;
	transform: translateX(-50%);
}

.hiristTechFlexWrapper {
	display: flex;
	justify-content: center;
	@media only screen and (max-width: 450px) {
		justify-content: space-between;
	}
	align-items: center;
	flex-direction: row;
	text-align: center;
	@media only screen and (max-width: 450px) {
		text-align: left;
	}
	height: 100%;
}

.hiristTechText {
	font-family: Nunito Sans;
	font-size: 17px;
	// font-size: 16px;
	// @media only screen and (max-width: 370px) {
	// 	font-size: 14px;
	// }
	color: #fff;
	margin-left: 20px;
	line-height: 1.3;
	.text-style-1 {
		font-weight: 800;
	}
}

.hiristTechCloseIcon {
	width: 14px;
	height: 14px;
	margin-left: 20px;
	@media only screen and (min-width: 830px) {
		margin-left: 120px;
	}
	margin-right: 20px;
    cursor: pointer;
}

.hiristTechButton {
	min-width: 123px;
    display: inline-block;
    padding: 11px 11px;
    margin-left: 10px;
	margin-right: 10px;
    border-radius: 5px;
    background-color: #e9630c;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
}