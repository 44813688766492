@charset "UTF-8";

 @font-face {
  font-family: 'fontello';
  src: url('./font/icon-no.eot?28079887');
  src: url('./font/icon-no.eot?28079887#iefix') format('embedded-opentype'),
       url('./font/icon-no.woff2?28079887') format('woff2'),
       url('./font/icon-no.woff?28079887') format('woff'),
       url('./font/icon-no.ttf?28079887') format('truetype'),
       url('./font/icon-no.svg?28079887#icon-no') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon-no';
    src: url('../font/icon-no.svg?28079887#icon-no') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-flexibleworkinghours:before { content: '\e701'; } /* '' */
.icon-gift:before { content: '\e702'; } /* '' */
.icon-gym-membership:before { content: '\e703'; } /* '' */
.icon-health-benefits:before { content: '\e704'; } /* '' */
.icon-health-insurance:before { content: '\e705'; } /* '' */
.icon-in-house-concierge-services:before { content: '\e706'; } /* '' */
.icon-in-house-medical-facility:before { content: '\e707'; } /* '' */
.icon-internal-career-mobility:before { content: '\e708'; } /* '' */
.icon-lactation-rooms:before { content: '\e709'; } /* '' */
.icon-leadership-development-programs:before { content: '\e710'; } /* '' */
.icon-library:before { content: '\e711'; } /* '' */
.icon-life-insurance:before { content: '\e712'; } /* '' */
.icon-marriage-and-childbirth-gifts:before { content: '\e713'; } /* '' */
.icon-maternity-and-paternity-leave:before { content: '\e714'; } /* '' */
.icon-medical-checkup:before { content: '\e715'; } /* '' */
.icon-meditation-room:before { content: '\e716'; } /* '' */
.icon-monthly-lunch:before { content: '\e717'; } /* '' */
.icon-on-site-gym:before { content: '\e718'; } /* '' */
.icon-onilne-medical:before { content: '\e719'; } /* '' */
.icon-onsite-prayer:before { content: '\e720'; } /* '' */
.icon-parant-policysvg:before { content: '\e721'; } /* '' */
.icon-parking-space:before { content: '\e722'; } /* '' */
.icon-performance-bonus:before { content: '\e723'; } /* '' */
.icon-personal-accident-insurance:before { content: '\e724'; } /* '' */
.icon-personal-development-and-training:before { content: '\e725'; } /* '' */
.icon-provision-of-snacks:before { content: '\e726'; } /* '' */
.icon-provison-of-food:before { content: '\e727'; } /* '' */
.icon-quarter-party:before { content: '\e728'; } /* '' */
.icon-recreation-and-sports-events:before { content: '\e729'; } /* '' */
.icon-recreational-area:before { content: '\e730'; } /* '' */
.icon-reward-and-recognition:before { content: '\e731'; } /* '' */
.icon-saving:before { content: '\e732'; } /* '' */
.icon-savings-and-retirement-guidance:before { content: '\e733'; } /* '' */
.icon-spouse-health-check-up:before { content: '\e734'; } /* '' */
.icon-summer-flex-schedule-program:before { content: '\e735'; } /* '' */
.icon-taxi:before { content: '\e736'; } /* '' */
.icon-teacoffee-machine:before { content: '\e737'; } /* '' */
.icon-team-outings-and-annual-celebrations:before { content: '\e738'; } /* '' */
.icon-transportation:before { content: '\e739'; } /* '' */
.icon-voluntary-parent-coverage:before { content: '\e740'; } /* '' */
.icon-work-from-home:before { content: '\e741'; } /* '' */
.icon-world-class-projects:before { content: '\e742'; } /* '' */
.icon-5-days-working:before { content: '\e743'; } /* '' */
.icon-24-hour-health-helpline:before { content: '\e744'; } /* '' */
.icon-24-hour-onsite-cafe:before { content: '\e745'; } /* '' */
.icon-24-hour-wellness-center:before { content: '\e746'; } /* '' */
.icon-birthday-and-anniversary-leave:before { content: '\e747'; } /* '' */
.icon-birthday-celebration:before { content: '\e748'; } /* '' */
.icon-cab-service:before { content: '\e749'; } /* '' */
.icon-cafeteria:before { content: '\e750'; } /* '' */
.icon-career_growth:before { content: '\e751'; } /* '' */
.icon-company-car:before { content: '\e752'; } /* '' */
.icon-company-leased-vehicle:before { content: '\e753'; } /* '' */
.icon-continuingeducationsupport:before { content: '\e754'; } /* '' */
.icon-convenienceservices:before { content: '\e755'; } /* '' */
.icon-discount:before { content: '\e756'; } /* '' */
.icon-employeeassistanceprogram:before { content: '\e757'; } /* '' */
.icon-employeefriendlypolicies:before { content: '\e758'; } /* '' */
.icon-esops:before { content: '\e759'; } /* '' */
.icon-esops2:before { content: '\e760'; } /* '' */
.icon-festive-celebrations:before { content: '\e761'; } /* '' */
.icon-work-anniversary-celebration:before { content: '\e762'; } /* '' */
.icon-down_arrow:before { content: '\e763'; } /* '' */
.icon-facebook:before { content: '\e764'; } /* '' */
.icon-facebook_footer:before { content: '\e765'; } /* '' */
.icon-google-plus_footer:before { content: '\e766'; } /* '' */
.icon-home_footer:before { content: '\e767'; } /* '' */
.icon-left_arrow:before { content: '\e768'; } /* '' */
.icon-linkedin:before { content: '\e769'; } /* '' */
.icon-linkedin_footer:before { content: '\e770'; } /* '' */
.icon-minus:before { content: '\e771'; } /* '' */
.icon-outline_plus:before { content: '\e772'; } /* '' */
.icon-outline_minus:before { content: '\e773'; } /* '' */
.icon-photo_view:before { content: '\e774'; } /* '' */
.icon-plus:before { content: '\e775'; } /* '' */
.icon-right_arrow:before { content: '\e776'; } /* '' */
.icon-top_arrow:before { content: '\e777'; } /* '' */
.icon-twitter:before { content: '\e778'; } /* '' */
.icon-twitter_footer:before { content: '\e779'; } /* '' */
.icon-video:before { content: '\e780'; } /* '' */
.icon-youtube_footer:before { content: '\e781'; } /* '' */
.icon-csr:before { content: '\e782'; } /* '' */
.icon-experience:before { content: '\e783'; } /* '' */
.icon-location:before { content: '\e784'; } /* '' */
.icon-cross:before { content: '\e785'; } /* '' */
.icon-plus2:before { content: '\e786'; } /* '' */
.icon-minus2:before { content: '\e787'; } /* '' */
.icon-perk1:before { content: '\e788'; } /* '' */
.icon-perk2:before { content: '\e789'; } /* '' */
.icon-pencil:before { content: '\e790'; } /* '' */
.icon-travel-assistance:before { content: '\e791'; } /* '' */
.icon-paid-sabbatical:before { content: '\e792'; } /* '' */
.icon-greenoffice:before { content: '\e793'; } /* '' */
.icon-gender-diversity:before { content: '\e794'; } /* '' */
.icon-angle-down:before { content: '\e800'; } /* '' */
.icon-down-open:before { content: '\e801'; } /* '' */
.icon-angle-right:before { content: '\e802'; } /* '' */
.icon-more:before { content: '\e803'; } /* '' */
.icon-course:before { content: '\e804'; } /* '' */
.icon-notification:before { content: '\e805'; } /* '' */
.icon-view-all:before { content: '\e806'; } /* '' */
.icon-logout:before { content: '\e807'; } /* '' */
.icon-left-arrow:before { content: '\e808'; } /* '' */
.icon-back:before { content: '\e809'; } /* '' */
.icon-add:before { content: '\e80a'; } /* '' */
.icon-company:before { content: '\e80b'; } /* '' */
.icon-consultant:before { content: '\e80c'; } /* '' */
.icon-login:before { content: '\e80d'; } /* '' */
.icon-premium:before { content: '\e80e'; } /* '' */
.icon-info:before { content: '\e80f'; } /* '' */
.icon-save:before { content: '\e810'; } /* '' */
.icon-tick:before { content: '\e811'; } /* '' */
.icon-showcase:before { content: '\e812'; } /* '' */
.icon-diversity:before { content: '\e813'; } /* '' */
.icon-search-big:before { content: '\e814'; } /* '' */
.icon-close:before { content: '\e815'; } /* '' */
.icon-down-arrow:before { content: '\e816'; } /* '' */
.icon-hemburger:before { content: '\e817'; } /* '' */
.icon-right-arrow:before { content: '\e818'; } /* '' */
.icon-search:before { content: '\e819'; } /* '' */
.icon-sort:before { content: '\e81a'; } /* '' */
.icon-filter:before { content: '\e81b'; } /* '' */
.icon-default-jobfeed:before { content: '\e81c'; } /* '' */
.icon-fallback-img:before { content: '\e81c'; } /* '' */
.icon-information:before { content: '\e81d'; } /* '' */
.icon-done:before { content: '\e81e'; } /* '' */
.icon-method:before { content: '\e820'; } /* '' */
.icon-exp:before { content: '\e821'; } /* '' */
.icon-fee:before { content: '\e822'; } /* '' */
.icon-page-up:before { content: '\e823'; } /* '' */
.icon-nivea:before { content: '\e82c'; } /* '' */
.icon-csr-1:before { content: '\e82d'; } /* '' */
.icon-growth:before { content: '\e82e'; } /* '' */
.icon-partnership-for-prevention:before { content: '\e82f'; } /* '' */
.icon-employee-assistance-program:before { content: '\e830'; } /* '' */
.icon-post_maternity:before { content: '\e831'; } /* '' */
.icon-health_club:before { content: '\e832'; } /* '' */
.icon-birthday:before { content: '\e84c'; } /* '' */
.icon-cab:before { content: '\e84d'; } /* '' */
.icon-cafe:before { content: '\e858'; } /* '' */
.icon-car:before { content: '\e859'; } /* '' */
.icon-car_insurance:before { content: '\e85a'; } /* '' */
.icon-clock:before { content: '\e85b'; } /* '' */
.icon-coffee_cup:before { content: '\e85c'; } /* '' */
.icon-coffee_machine:before { content: '\e85d'; } /* '' */
.icon-conceirge:before { content: '\e85e'; } /* '' */
.icon-dart_arrow:before { content: '\e85f'; } /* '' */
.icon-discounts:before { content: '\e860'; } /* '' */
.icon-doctor:before { content: '\e861'; } /* '' */
.icon-dollars_bills:before { content: '\e862'; } /* '' */
.icon-dumbell:before { content: '\e863'; } /* '' */
.icon-employeefriendly:before { content: '\e864'; } /* '' */
.icon-gymnast_practice:before { content: '\e865'; } /* '' */
.icon-help_the_elderly:before { content: '\e866'; } /* '' */
.icon-helpline:before { content: '\e867'; } /* '' */
.icon-helpline2:before { content: '\e868'; } /* '' */
.icon-higher-ed:before { content: '\e869'; } /* '' */
.icon-ice_cream:before { content: '\e86a'; } /* '' */
.icon-insurance:before { content: '\e86b'; } /* '' */
.icon-library-1:before { content: '\e86c'; } /* '' */
.icon-man_working:before { content: '\e86d'; } /* '' */
.icon-maternity:before { content: '\e86e'; } /* '' */
.icon-medical:before { content: '\e86f'; } /* '' */
.icon-medicalfees:before { content: '\e870'; } /* '' */
.icon-meditation:before { content: '\e871'; } /* '' */
.icon-mortarboard:before { content: '\e872'; } /* '' */
.icon-park:before { content: '\e873'; } /* '' */
.icon-phone:before { content: '\e874'; } /* '' */
.icon-pie_chart:before { content: '\e875'; } /* '' */
.icon-presentation:before { content: '\e876'; } /* '' */
.icon-pulse:before { content: '\e877'; } /* '' */
.icon-rewards:before { content: '\e878'; } /* '' */
.icon-savings:before { content: '\e879'; } /* '' */
.icon-schedule:before { content: '\e87a'; } /* '' */
.icon-voucher:before { content: '\e87b'; } /* '' */
.icon-default:before { content: '\e87c'; } /* '' */
.icon-mobility:before { content: '\e881'; } /* '' */
.icon-projects:before { content: '\e882'; } /* '' */
.icon-sports:before { content: '\e883'; } /* '' */
.icon-training:before { content: '\e884'; } /* '' */
.icon-celebrations:before { content: '\e885'; } /* '' */
.icon-save-white:before { content: '\e8bf'; } /* '' */
.icon-single-arrow:before { content: '\e8c0'; } /* '' */
.icon-double-arrow:before { content: '\e8c1'; } /* '' */
.icon-home:before { content: '\e8c3'; } /* '' */
.icon-profile:before { content: '\e8c4'; } /* '' */
.icon-no-notification:before { content: '\e8c5'; } /* '' */
.icon-insights:before { content: '\e8dd'; } /* '' */
.icon-undo:before { content: '\e8de'; } /* '' */
.icon-no-messages:before { content: '\e965'; } /* '' */
.icon-clip:before { content: '\e966'; } /* '' */
.icon-no-of-application:before { content: '\e967'; } /* '' */
.icon-no-of-recruiter:before { content: '\e968'; } /* '' */
.icon-save-orange:before { content: '\e969'; } /* '' */
.icon-right-straight-arrow:before { content: '\e970'; } /* '' */
.icon-download-tick:before { content: '\e971'; } /* '' */
.icon-exclusive-design:before { content: '\e972'; } /* '' */
.icon-left-straight-arrow:before { content: '\e973'; } /* '' */
.icon-fresh-job:before { content: '\e974'; } /* '' */
.icon-sad:before { content: '\e975'; } /* '' */
.icon-emerging-technologies-roles:before { content: '\e976'; } /* '' */
.icon-devops-jobs:before { content: '\e977'; } /* '' */
.icon-backend-developer-jobs:before { content: '\e978'; } /* '' */
.icon-frontend-developer-jobs:before { content: '\e979'; } /* '' */
.icon-mobile-applications-jobs:before { content: '\e980'; } /* '' */
.icon-analytics-data-science:before { content: '\e981'; } /* '' */
.icon-no-insights:before { content: '\e982'; } /* '' */
.icon-alert:before { content: '\e983'; } /* '' */
.icon-eye:before { content: '\e984'; } /* '' */
.icon-startup:before { content: '\e985'; } /* '' */
.icon-others:before { content: '\e986'; } /* '' */
.icon-project-managment:before { content: '\e987'; } /* '' */
.icon-semiconductor-vlsi-eda:before { content: '\e988'; } /* '' */
.icon-enterprise-sap-oracle:before { content: '\e989'; } /* '' */
.icon-product-managment:before { content: '\e990'; } /* '' */
.icon-quality-assurance:before { content: '\e991'; } /* '' */
.icon-ui-design:before { content: '\e992'; } /* '' */
.icon-tnc:before { content: '\e993'; } /* '' */
.icon-refund-policy:before { content: '\e994'; } /* '' */
.icon-settings:before { content: '\e995'; } /* '' */
.icon-saved-jobs:before { content: '\e996'; } /* '' */
.icon-removed-jobs:before { content: '\e997'; } /* '' */
.icon-pending-test:before { content: '\e998'; } /* '' */
.icon-setting-info:before { content: '\e999'; } /* '' */
.icon-notifications:before { content: '󡀀'; } /* '\e1000' */
.icon-my-interview:before { content: '󡀁'; } /* '\e1001' */
.icon-applied-jobs:before { content: '󡀂'; } /* '\e1002' */
.icon-chat:before { content: '󡀃'; } /* '\e1003' */
.icon-download:before { content: '󡀄'; } /* '\e1004' */
.icon-duration:before { content: '󡀅'; } /* '\e1005' */
.icon-timer:before { content: '󡀆'; } /* '\e1006' */
.icon-calendar:before { content: '󡀇'; } /* '\e1007' */
.icon-toast-error:before { content: '󡀈'; } /* '\e1008' */
.icon-toast-info:before { content: '󡀉'; } /* '\e1009' */
.icon-toast-success:before { content: '󡀐'; } /* '\e1010' */
.icon-face-to-face:before { content: '󡀑'; } /* '\e1011' */
.icon-call:before { content: '󡀒'; } /* '\e1012' */
.icon-email:before { content: '󡀓'; } /* '\e1013' */
.icon-book-slot:before { content: '󡀔'; } /* '\e1014' */
.icon-video-slot:before { content: '󡀕'; } /* '\e1015' */
.icon-my-chat-link:before { content: '󡀖'; } /* '\e1016' */
.icon-category-latest-jobs:before { content: '󡀗'; } /* '\e1017' */
.icon-hand-pointer:before { content: '󡀘'; } /* '\e1018' */
.icon-profile-projector:before { content: '󡀙'; } /* '\e1019' */
.icon-quote:before { content: '󡀠'; } /* '\e1020' */
.icon-assesment-expired:before { content: '󡀡'; } /* '\e1021' */
.icon-not-authorized:before { content: '󡀢'; } /* '\e1022' */
.icon-attachment:before { content: '󡀣'; } /* '\e1023' */