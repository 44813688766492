.icon-fallback-img {
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
    margin-left: -2px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    padding: 20px 10px;
    color: #fff;
    position: absolute;
    font-size: 56px;
    background-color: #f2998f;
}