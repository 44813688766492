body {
  margin: 0;
  font-size: 14px;
  line-height: 1.1;
  // scroll-behavior: smooth;
  // scrollbar-width: none;
  // -ms-overflow-style: none;  // IE 10+
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
} 

img {
  vertical-align: middle;
  border: 0;
}

a {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none !important;
}

a:hover {
  color: unset;
}

form {
  display: block;
  margin-top: 0em;
}

select, textarea {
  font-family: inherit;
  line-height: inherit;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.sidebar-heading {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 13px;
  width: 74%;
}

circle {
  transition: stroke-dashoffset 1s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.showcase-loader-wrapper {
  position: relative;

  @media screen and (min-width: 1280px) {
    min-height: 200px;
  }

  @media screen and (max-width: 1279px) and (min-width: 769px) {
    min-height: 170px;
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    min-height: 120px;
  }

  @media screen and (max-width: 425px) {
    min-height: 170px;
  }
}
